import { Button, Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import LoginLayout from "components/layouts/login";
import { Link } from "gatsby";
import React, { FC } from "react";
import routes from "routes";

import styles from "./style.module.scss";

type Props = FormComponentProps;

const Forgot: FC<Props> = ({ form }) => (
  <LoginLayout title="Восстановление пароля">
    <div className={styles.block}>
      <div className="row">
        <div className="col-xl-12">
          <div className={styles.inner}>
            <div className={styles.form}>
              <h4 className="text-uppercase">
                <strong>Восстановление пароля</strong>
              </h4>
              <br />
              <Form layout="vertical" hideRequiredMark={true}>
                <Form.Item label="Имя пользователя или email">
                  {form.getFieldDecorator("username", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message:
                          "Пожалуйста введите имя пользователя или email",
                      },
                    ],
                  })(<Input size="default" />)}
                </Form.Item>
                <div className="form-actions">
                  <Button
                    type="primary"
                    className="mr-4"
                    htmlType="submit"
                    loading={false}
                  >
                    Восстановить пароль
                  </Button>
                  <Link
                    to={routes.login}
                    className="utils__link--blue utils__link--underlined"
                  >
                    Назад
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LoginLayout>
);

export default Form.create()(Forgot);
